<template>
  <div class="container content">
    <div class="headline">
      <h1>{{ $t('message.company.header') }}</h1>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-parent is-4">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.partner.instructions.header') }}</p>
          <div v-html="$t('message.partner.instructions.txt')"></div>
        </article>
     </div>
      <div class="tile is-parent is-8">
        <article class="tile is-child box">
          <p class="title">{{ $t('message.company.show.header') }}</p>
          <div class="columns">
           <div class="column is-half">
               <b-field :label="$t('message.company.show.name')">
                 <b-input v-model="editCompany.name"></b-input>
              </b-field>
              <b-field :label="$t('message.company.show.email')">
                <b-input v-model="editCompany.email"></b-input>
              </b-field>
              <b-field :label="$t('message.company.show.mobile')">
                <b-input v-model="editCompany.mobile"></b-input>
              </b-field>
              <b-field :label="$t('message.company.show.address')">
                <b-input v-model="editCompany.address"></b-input>
              </b-field>
              <b-field :label="$t('message.company.show.zip')">
                <b-input v-model="editCompany.zip"></b-input>
              </b-field>
              <b-field :label="$t('message.company.show.city')">
                <b-input v-model="editCompany.city"></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <b-field :label="$t('message.company.show.vat')">
                {{ editCompany.vat }}
              </b-field>
              <b-field :label="$t('message.company.show.www')">
                <b-input v-model="editCompany.www"></b-input>
              </b-field>
             </div>
            </div>

            <b-button
              :loading="false"
              :label="$t('message.company.save.button')"
              size="is-large"
              class="fullwidth"
              type="is-success" 
              v-on:click="save" />

        </article>
     </div>
    </div>  
  </div>
</template>

<script>
export default {
    data() {
        return {
          editCompany: {
            name: '',
            address: '',
            email: '',
            mobile: '',
            zip: '',
            city: '',
            vat: '',
            logo: '',
            www: '',
          }
        }
    },
    methods: {
        save() {
          this.$store.dispatch("editCompany", this.editCompany).then((response) => {
              var status = response.status
              if (status >= 200 && status < 300) {
                  this.$router.push('/yritys')
              } else {
                  this.toastFailure(this.$t('message.company.toaster.editError'));
              }
          }).catch((error) => {
              this.ERROR(error);
              this.toastFailure(this.$t('message.company.toaster.editError'));
          });
        },
          /*
          this.$store.dispatch("validatePartner", this.editCompany).then((response) => {
              console.log(response)
              this.$store.dispatch("editPartner", this.editCompany).then((response) => {
                  var status = response.status
                  if (status >= 200 && status < 300) {
                      this.$router.push('/kumppani')
                  } else {
                      this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
                  }
              }).catch((error) => {
                  this.ERROR(error);
                  this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
              });
          }).catch((error) => {
              var displayed = false
              var msg = error.response.data
              for (const _key in msg.errors) {
                  this.toastFailure(this.$t("message.error.company." + _key));
                  displayed = true
              }
              if (!displayed) {
                  this.toastFailure(this.$t('message.partner.toaster.company.creationError'));
              }
              console.log(error, error.response)
          })
        },*/
    },
    computed: {
    },
    mounted() {
        var __id = this.$route.params.company_id
        this.$store.dispatch("fetchCompanies2").then((response) => {
          var company = response.data.find(p => p.id == parseInt(__id))
          this.editCompany = {...company}
        });
    }
}
</script>